import PermaLink from '../PermaLink/PermaLink'

interface IMainTitle {
  title: string
  className?: string
}

const MainTitle = ({ title, className = '' }: IMainTitle) => {
  return (
    <h1 className={`mb-5 ${className}`}>
      {title}
      <PermaLink title={title} />
    </h1>
  )
}

export default MainTitle
