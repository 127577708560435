import VideoCardInfo from '../VideoCardInfo/VideoCardInfo'
import videos from '../../content/qml/videos'
import './style.scss'

const VideosCards = () => {
  return (
    <div className="videoCards__container">
      {videos.cards.map((item) => {
        return (
          <div className="videoCard" key={item.title}>
            <VideoCardInfo {...item} />
          </div>
        )
      })}
    </div>
  )
}

export default VideosCards
