import './style.scss'
import LinkComponent from '../LinkComponent/LinkComponent'
import { slugifyString } from 'shared-utilities'

const PermaLink = ({
  title,
  className,
}: {
  title: string
  className?: string
}) => {
  return (
    <span
      className={`permalink ${className ? className : ''}`}
      id={slugifyString(title)}
    >
      <LinkComponent
        className="headerlink"
        href={`#${slugifyString(title.replaceAll('.', ''))}`}
        aria-label="Permalink to this headline"
      >
        ¶
      </LinkComponent>
    </span>
  )
}

export default PermaLink
