import { FC, useRef, useState } from 'react'
import { useSetSlideLinkTabIndex } from 'shared-utilities'
import Image from '../Image/Image'
import './style.scss'

interface IVideoCardInfo {
  title: string
  subtitle?: string
  text?: string
  image?: string
  videoId: string
  className?: string
}

const VideoCardInfo: FC<IVideoCardInfo> = ({
  title,
  subtitle,
  text,
  videoId,
  image,
  className = '',
}) => {
  const cardRef = useRef(null)
  const [isVisible, toggleIsVisible] = useState<boolean>(true)
  useSetSlideLinkTabIndex(cardRef, toggleIsVisible, true)

  return (
    <div className={`Video__card ${className}`} ref={cardRef}>
      <a
        data-testid="video-link"
        href={`https://youtube.com/watch?v=${videoId}`}
        target="_blank"
        rel="noreferrer"
        tabIndex={isVisible ? 0 : -1}
      >
        <div className="Video__card__img__wrapper">
          <Image
            src={
              image
                ? image
                : `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`
            }
            withoutPrefix={!image}
            data-testid="preview-image"
            className="Video__card__img"
            alt=""
          />
        </div>
        <div className="Video__card__body">
          <h2>{title}</h2>
          {subtitle && <p className="Video__card__subtitle">{subtitle}</p>}
          {text && <p className="Video__card__text">{text}</p>}
        </div>
        <div className="Video__card__footer">
          <span className="flex items-center">
            Watch
            <i className="bx bx-chevron-right ml-2 text-3xl"></i>
          </span>
        </div>
      </a>
    </div>
  )
}

export default VideoCardInfo
