import Seo from '../../components/seo'
import VideosCards from '../../components/VideosCards/VideosCards'
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import MainTitle from '../../components/MainTitle/MainTitle'
import videos from '../../content/qml/videos'
import '../../scss/pages/videos.scss'

const { meta_title, meta_description, meta_image } = videos

const VideosPage = () => {
  return (
    <>
      <Breadcrumbs pageTitle="Videos" />
      <section className="videoSection">
        <div className="videoSection__info">
          <MainTitle title={videos.title} />
          <p>{videos.text_1}</p>
        </div>
        <div className="videoSection__videos">
          <VideosCards />
        </div>
      </section>
    </>
  )
}

export default VideosPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
