import { withPrefix } from 'gatsby'
import { FC, ImgHTMLAttributes } from 'react'

interface ImgProps extends ImgHTMLAttributes<HTMLImageElement> {
  withoutPrefix?: boolean
}

const Image: FC<ImgProps> = ({ withoutPrefix, ...props }) => {
  return (
    props.src && (
      <img
        {...{
          ...props,
          src: withoutPrefix ? props.src : withPrefix(props.src),
        }}
      />
    )
  )
}

export default Image
